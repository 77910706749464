import React, { useEffect, useMemo, useState } from "react";
import logo from "../assets/logo.png";
import icon from "../assets/icon.png";
import bgImage from "../assets/bgImage.png";
import bullionStats from "../assets/bullioStats.png";
import { Box, LinearProgress, Modal, Typography } from "@mui/material";
import "../App.css";
import moment from "moment-timezone";
import io from "socket.io-client";
import Carousel from "react-material-ui-carousel";
import {
  allCommodities,
  allNewses,
  findBanners,
  getAdminProfile,
  getDXYindexingValue,
  getEconomicNews,
  getGoldPriceNews,
  getLiveValueTypeForDisplay,
  getLondonFixValue,
  getRatio,
  getSocketUrl,
  getSpread,
} from "../sevice/home";
import MarketClose from "../components/MarketClose";
import Blocked from "../components/Blocked";
import SubscriptionExpired from "../components/SubscriptionExpired";
import SubscriptionExpiringSoon from "../components/SubscriptionExpiringSoon";
import EconomicNewsModal from "../components/EconomicNewsModal";
import { commodityCalculation } from "../components/constants";

const { makeStyles } = require("@mui/styles");

let socket = localStorage.getItem("socketUrl")
  ? io(JSON.parse(localStorage.getItem("socketUrl")))
  : null;

const socket2 = io(process.env.REACT_APP_BACKEND_URL, { path: "/latest" });
const adminId = process.env.REACT_APP_ADMIN_ID;

const useStyles = makeStyles((theme) => ({
  mainBody: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "linear-gradient(180deg, #201C11 12.32%, #7D681B 138.11%)",
  },
  mainContainer: {
    width: "100%",
    height: "100vh",
    margin: "auto",
    padding: "2em",
    boxSizing: "border-box",
    columnGap: "2rem",
    display: "flex",
    flexDirection: "column",
    gap: 23,
    justifyContent: "space-between",
  },
  header: {
    width: "100%",
    height: "40%",
    display: "flex",
    justifyContent: "space-between",
    gap: 10,
  },
  spotRate: {
    height: "100%",
    width: "50%",
    display: "flex",
    flexDirection: "column",
    color: "#16341B",
    background: "#FFFFFF",
    color: "#000000",
    borderRadius: "10px",
  },
  spotRateHeader: {
    display: "flex",
    height: "22%",
    zIndex: 1000,
  },
  askOrBuyBox: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  spotRateBody: {
    height: "88%",
    display: "flex",
    padding: "1em",
    justifyContent: "space-evenly",
    flexDirection: "column",
  },
  spotRateRow: {
    height: "48%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
  },
  spotRateGoldBox: {
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    justifyContent: "left",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateSilverBox: {
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    justifyContent: "left",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateGoldValueBox: {
    color: "#FFFFFF",
    border: "1px solid #fff",
    borderRadius: "5px",
    width: "8vw",
    height: "6vh",
    display: "flex",
    alignItems: "center",
    transition: "background-color 0.5s ease",
  },
  spotRateSilverValueBox: {
    color: "#FFFFFF",
    border: "1px solid #fff",
    borderRadius: "5px",
    width: "8vw",
    height: "5.5vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color 0.5s ease",
  },
  highLowBox: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    fontSize: ".9vw",
  },
  high: {
    background: "#1C6DB9",
    paddingRight: "9px",
    paddingLeft: "9px",
    display: "inline-block",
    width: "fit-content",
  },
  low: {
    background: "#D63832",
    paddingRight: "9px",
    paddingLeft: "9px",
    display: "inline-block",
    width: "fit-content",
  },
  logo: {
    height: "100%",
    width: "30%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: 30,
    alignItems: "center",
  },
  logoImg: {
    height: "auto",
    width: "50%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  time: {
    width: "30%",
    display: "flex",
    color: "#000000",
    alignItems: "center",
    flexDirection: "column",
    gap: 20,
    justifyContent: "center",
    background: "#FFFFFF",
    borderRadius: "10px",
  },
  commodity: {
    height: "40%",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  table: {
    gridArea: "table",
    gridTemplateColumns: "1fr",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    boxSizing: "border-box",
    height: "100%",
    width: "60%",
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  tableHeader: {
    backgroundColor: "#000000",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#FFFFFF",
    height: "17%",
  },
  tableContent: {
    "&::-webkit-scrollbar": {
      display: "none", // Hide scrollbar
    },
    display: "flex",
    flexDirection: "column",
    gap: 10,
    overflowX: "scroll",
    height: "80%",
  },
  tableRow1: {
    minHeight: "22%",
    display: "flex",
    background: "#FFFFFF",
  },
  tableRowColumn: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    color: "#000000",
    justifyContent: "flex-start",
    fontSize: "2vw",
    paddingLeft: "2.2rem",
  },
  banner: {
    height: "100%",
    width: "37%",
    display: "flex",
    flexDirection: "column",
    gap: 15,
    alignItems: "center",
  },
  carousel: {
    height: "90%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "35px",
  },
  bannerImg: {
    width: "100%",
    height: "30vh",
    objectFit: "cover",
    borderRadius: "35px",
  },
  bullionStatsImg: {
    width: "13vw",
    height: "auto",
  },
  updates: {
    color: "#000000",
    display: "flex",
    flexDirection: "column",
    height: "20%",
    color: "#FFFFFF",
    gap: 7,
  },
  updatesContent: {
    color: "#000000",
    display: "flex",
    height: "30%",
    background: "#FFFFFF",
    width: "100%",
  },

  blur: {
    filter: "blur(8px)",
  },
}));

const Home = () => {
  const classes = useStyles();
  const [adminData, setAdminData] = useState();
  const [openSubscriptionExpireSoon, setOpenSubscriptionExpireSoon] =
    useState(false);
  const [openSubscriptionExpierd, setOpenSubscriptionExpierd] = useState(false);
  const [openBlocked, setOpenBlocked] = useState(false);
  const [isMarketOpen, setIsMarketOpen] = useState(true);
  const [render, setRender] = useState(true);
  const [dxyValue, setDXYvlue] = useState();

  const [gold, setGold] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [silver, setSilver] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [ratio, setRatio] = useState({
    Buyers: "0%",
    chgValue: "+0%",
    commodity: "Gold",
  });
  const [displayBidOrBuy, setDisplayBidOrBuy] = useState({
    bidOrBuy: "Bid",
    askOrSell: "Ask",
  });
  const [spread, setSpread] = useState({
    goldAskSpread: 0,
    goldBidSpread: 0,
    silverAskSpread: 0,
    silverBidSpread: 0,
    goldLowSpread: 0,
    goldHighSpread: 0,
    silverLowSpread: 0,
    silverHighSpread: 0,
  });
  const [news, setNews] = useState([]);
  const [banners, setBanners] = useState([]);
  const [goldNews, setGoldNews] = useState({});
  const [commodities, setCommodities] = useState([]);
  const [londonFixValue, setLondonFixValue] = useState({});

  ////////////////////// Block //////////////////////////
  const handleOpenBlocked = () => setOpenBlocked(true);
  const handleCloseBlocked = () => {
    window.location.reload();
  };

  ///////////////////// Subscription Expierd /////////////////
  const handleOpenSubscriptionExpierd = () => setOpenSubscriptionExpierd(true);
  const handleCloseSubscriptionExpierd = () => window.location.reload();

  ///////////////////// Subscription ExpierSoon /////////////////////
  const handleExpireSoonOpen = () => setOpenSubscriptionExpireSoon(true);
  const handleOpenExpireSoonClose = () => setOpenSubscriptionExpireSoon(false);

  ///////////////////// Check is Market Closed  /////////////////////
  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment().tz(adminData?.time_zone);
      const dayOfWeek = now?.day();
      const hour = now?.hour();
      if (
        (dayOfWeek >= 1 && dayOfWeek <= 5 && hour >= 1 && hour < 2) || // Monday to Friday, 1AM to 2AM
        (dayOfWeek === 6 && hour >= 1) || // Saturday, 1AM onwards
        dayOfWeek === 0 || // Sunday
        (dayOfWeek === 1 && hour < 2) // Monday, before 2AM
      ) {
        setIsMarketOpen(false);
      } else {
        setIsMarketOpen(true);
      }
    }, 1000); // Check every minute

    return () => clearInterval(interval);
  }, [adminData?.time_zone]);

  useMemo(() => {
    if (socket && render) {
      setRender(false);
      socket.on("connect", () => {});
      socket.on("disconnect", () => {});
      socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
      });
      socket.on("gold-rate-change", (data) => {
        // setIsMarketOpen(data.data.isMarketOpen);
        setGold((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
      socket.on("silver-rate-change", (data) => {
        setSilver((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
    }
  }, [socket]);

  useEffect(() => {
    socket2.on("connect", () => {});
    socket2.on("disconnect", () => {});
    socket2.emit("join_room", { roomId: adminId });
    socket2.on("commodityRerender", (data) => {
      handleFindCommodities();
    });
    socket2.on("newsRerender", (data) => {
      hadlefetchNews();
    });
    socket2.on("spreadRerender", (data) => {
      handleFindSpread();
    });
    socket2.on("loginChecking", (data) => {
      // handleloginChecking();
    });
    socket2.on("mediaRerender", (data) => {
      handelFindBanners();
    });
    socket2.on("adminBlockRerender", (data) => {
      handleFindAdminProfile();
    });
    socket2.on("socketRerender", (data) => {
      localStorage.removeItem("socketUrl");
      window.location.reload();
    });
    socket2.on("reloadRender", (data) => {
      window.location.reload();
    });
    socket2.on("upgradeRerender", (data) => {
      handleFindAdminProfile();
    });
    socket2.on("bidAndAskTitleRerender", (data) => {
      handleFindLiveValueTypeForDisplay();
    });
    socket2.on("economicNewsRender", (data) => {
      handleFindEconomicNews();
    });
  }, []);

  const handleFindCommodities = async () => {
    const res = await allCommodities();
    if (Array.isArray(res.commodities)) {
      setCommodities(res.commodities);
    }
  };
  const handleFindSpread = async () => {
    const res = await getSpread();
    if (res) {
      setSpread(res);
    }
  };

  const handleFindLondonFixValue = async () => {
    const { data } = await getLondonFixValue();
    if (data) {
      setLondonFixValue(data?.GetLondonFixByYearV3?.results[0]);
    }
  };

  const hadlefetchNews = async () => {
    const res = await allNewses();
    if (Array.isArray(res)) {
      setNews(res);
    }
  };
  const handleFindGoldPriceNews = async () => {
    const res = await getGoldPriceNews();
    if (res.length > 0) setGoldNews(res[0]);
  };

  const handelFindBanners = async () => {
    const res = await findBanners();
    if (Array.isArray(res)) {
      setBanners(res);
    }
  };
  const handleFindLiveValueTypeForDisplay = async () => {
    const res = await getLiveValueTypeForDisplay();
    if (res) {
      setDisplayBidOrBuy(res);
    }
  };
  const handleFindRatio = async () => {
    const res = await getRatio();
    if (res?.data) {
      setRatio(res.data);
    }
  };
  const getDXYValue = async () => {
    const res = await getDXYindexingValue();
    if (res) {
      setDXYvlue(res);
    }
  };

  const handleFindSocketUrl = async () => {
    const res = await getSocketUrl();
    if (res) {
      socket = io(res?.socketURL);
      localStorage.setItem("socketUrl", JSON.stringify(res?.socketURL));
    }
  };

  const handleFindAdminProfile = async () => {
    const res = await getAdminProfile();
    setAdminData(res);
    if (!res.status || res.isBlocked) {
      handleOpenBlocked();
    } else if (!res.isBlocked && openBlocked) {
    } else {
      handlecheckSubscriptionExpierd(res);
    }
  };

  const handlecheckSubscriptionExpierd = (data) => {
    const givenDate = new Date(data?.package?.expire_date);
    let a = givenDate.toISOString();
    let b = new Date().toISOString();
    if (a < b) {
      handleOpenSubscriptionExpierd();
    }
  };

  useEffect(() => {
    if (adminData) {
      const interval = setInterval(() => {
        checkSubscriptionExpireSoon(adminData);
      }, 60 * 60 * 1000);
      return () => clearInterval(interval);
    }
  }, [adminData]);

  const checkSubscriptionExpireSoon = (data) => {
    const givenDate = new Date(data?.package?.expire_date);
    const oneMonthAgo = new Date(givenDate);
    oneMonthAgo.setDate(givenDate.getDate() - 30);
    let a = oneMonthAgo.toISOString();
    let b = new Date().toISOString();
    if (b >= a) {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      if (currentHour === 10) {
        handleExpireSoonOpen();
      }
    }
  };

  useEffect(() => {
    handleFindCommodities();
    handleFindSpread();
    hadlefetchNews();
    handelFindBanners();
    handleFindLiveValueTypeForDisplay();
    handleFindRatio();
    handleFindSocketUrl();
    handleFindAdminProfile();
    handleFindEconomicNews();
    handleFindGoldPriceNews();
    getDXYValue();
    handleFindLondonFixValue();
  }, []);

  setInterval(() => {
    handleFindLondonFixValue();
  }, 3600000);

  const [economicNews, setEconomicNews] = useState([]);
  const [openNews, setOpenNews] = useState(false);
  const handleOpenopenNews = () => setOpenNews(true);
  const handleCloseopenNews = () => setOpenNews(false);

  const [curEconomicNews, setCurEconomicNews] = useState([]);
  const handleFindEconomicNews = async () => {
    const res = await getEconomicNews();
    setEconomicNews(res);
  };

  useEffect(() => {
    const timeInterwell = setInterval(() => {
      economicNewsFinding();
    }, 1000 * 60 * 1);
    economicNewsFinding();
    return () => clearInterval(timeInterwell);
  }, [economicNews]);

  const economicNewsFinding = () => {
    if (economicNews.length) {
      const currentTime = new Date(new Date().toUTCString());
      const oneHourAgo = new Date(currentTime);
      oneHourAgo.setHours(currentTime.getHours() + 1);
      oneHourAgo.setSeconds(0);
      oneHourAgo.setMilliseconds(0);

      const currentTimeMin = new Date(new Date().toUTCString());
      const fiveMinAgo = new Date(currentTimeMin);
      fiveMinAgo.setMinutes(currentTimeMin.getMinutes());
      fiveMinAgo.setSeconds(0);
      fiveMinAgo.setMilliseconds(0);

      let objectBeforeFiveMin = economicNews.filter((obj) => {
        let utcDate = new Date(obj.date);
        let timeDiff =
          new Date(
            utcDate?.toLocaleString("en-US", { timeZone: "UTC" })
          ).getTime() - fiveMinAgo.getTime();

        let minutesDiff = Math.floor(timeDiff / (1000 * 60));
        return minutesDiff <= 5 && minutesDiff >= 0;
      });
      let objectBeforeOneHour = economicNews.filter((obj) => {
        let utcDate = new Date(obj.date);
        let timeDiff =
          new Date(
            utcDate?.toLocaleString("en-US", { timeZone: "UTC" })
          ).getTime() - oneHourAgo.getTime();

        let minutesDiff = Math.floor(timeDiff / (1000 * 60));
        return minutesDiff <= 5 && minutesDiff >= -5;
      });
      if (objectBeforeFiveMin.length) {
        objectBeforeFiveMin = objectBeforeFiveMin?.sort(
          (a, b) => b.priority - a.priority
        );
        setCurEconomicNews(objectBeforeFiveMin);
        handleOpenopenNews();
      } else if (objectBeforeOneHour.length) {
        objectBeforeOneHour = objectBeforeOneHour?.sort(
          (a, b) => b.priority - a.priority
        );
        setCurEconomicNews(objectBeforeOneHour);
        handleOpenopenNews();
      } else {
        setCurEconomicNews([]);
        handleCloseopenNews();
      }
    } else {
      setCurEconomicNews([]);
    }
  };

  const formatDate = () => {
    return moment(new Date()).format("DD MMM YYYY");
  };
  const formatday = () => {
    return moment(new Date()).format("dddd");
  };
  const formatTime = () => {
    return new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const progress = Number(ratio?.Buyers?.match(/\d+/)[0]);
  const buyersColor = "#7E920B";
  const sellersColor = "#E83C3C";
  const progressStyle = { backgroundColor: sellersColor };
  const barStyle = { backgroundColor: buyersColor };

  return (
    <div
      style={{
        backgroundImage: `url(${bgImage})`,
        width: "100dvw",
        height: "100dvh",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
      className={(openSubscriptionExpierd || openBlocked) && classes.blur}
    >
      <Box className={classes.mainBody}>
        <Box className={classes.mainContainer}>
          {!isMarketOpen && <MarketClose timeZone={adminData?.time_zone} />}
          <Box className={classes.header}>
            <Box className={classes.logo}>
              <img className={classes.logoImg} src={logo} alt="amiceLogo" />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 10,
                  px: 2,
                  color: "white",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "2.9vw",
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    mb: -2,
                  }}
                >
                  {formatTime()
                    .split("")
                    .map((item, index) => (
                      <span key={index}>{item.toUpperCase()}</span>
                    ))}
                </Typography>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "1.5vw",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      mb: -1,
                    }}
                  >
                    {formatday()
                      .split("")
                      .map((item, index) => (
                        <span key={index}>{item.toUpperCase()}</span>
                      ))}
                  </Typography>
                  <Typography
                    sx={{
                      letterSpacing: "1px",
                      fontSize: "1.5vw",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    {formatDate()
                      .toUpperCase()
                      .split("")
                      .map((item, index) => (
                        <span key={index}>{item.toUpperCase()}</span>
                      ))}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className={classes.spotRate}>
              <Box
                sx={{
                  height: "55%",
                  width: "100%",
                  display: "flex",
                  borderBottom: "2px solid black",
                }}
              >
                <Box
                  sx={{
                    width: "30%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    p: 3,
                    paddingLeft: 6,
                    gap: 2,
                  }}
                >
                  <Typography sx={{ fontSize: "1.2vw", fontWeight: "bold" }}>
                    SPOT RATE
                  </Typography>
                  <Typography sx={{ fontSize: "2vw", fontWeight: "bold" }}>
                    GOLD
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "35%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    p: 3,
                    gap: 1,
                  }}
                >
                  <Box className={classes.askOrBuyBox}>
                    <Typography
                      style={{
                        padding: "0px 5px 0px 5px",
                        color: "#FFFFFF",
                        fontSize: ".8vw",
                        fontWeight: "bold",
                        background: "black",
                      }}
                    >
                      $
                    </Typography>

                    <Typography sx={{ fontSize: "1.2vw", fontWeight: "bold" }}>
                      {displayBidOrBuy?.bidOrBuy?.toUpperCase()}
                    </Typography>
                    <Typography style={{ fontSize: "1.2vw" }}>oz</Typography>
                  </Box>

                  <Box
                    className={classes.spotRateGoldValueBox}
                    sx={{
                      background:
                        Number(gold?.cur?.bid) < Number(gold?.pre?.bid)
                          ? "#D63832"
                          : Number(gold?.cur?.bid) > Number(gold?.pre?.bid)
                          ? "#1C6DB9"
                          : "#002223",
                      px: 1.5,
                    }}
                  >
                    <Typography sx={{ fontSize: "2.2vw", fontWeight: "bold" }}>
                      {!gold?.cur?.bid
                        ? "0.00"
                        : (
                            Number(spread.goldBidSpread) +
                            Number(gold?.cur?.bid)
                          ).toFixed(2)}
                    </Typography>
                  </Box>
                  <Box className={classes.highLowBox}>
                    <Box className={classes.low}>
                      <Typography
                        style={{ fontSize: ".8vw", color: "#FFFFFF" }}
                      >
                        Low
                      </Typography>
                    </Box>
                    <Typography style={{ fontSize: "1vw" }}>
                      {!gold?.cur?.bid
                        ? "0.00"
                        : (
                            Number(spread.goldLowSpread) +
                            Number(gold?.cur?.lowPrice)
                          ).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "35%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    p: 3,
                    gap: 1,
                  }}
                >
                  <Box className={classes.askOrBuyBox}>
                    <Typography
                      style={{
                        padding: "0px 5px 0px 5px",
                        color: "#FFFFFF",
                        fontSize: ".8vw",
                        fontWeight: "bold",
                        background: "black",
                      }}
                    >
                      $
                    </Typography>
                    <Typography sx={{ fontSize: "1.2vw", fontWeight: "bold" }}>
                      {displayBidOrBuy?.askOrSell?.toUpperCase()}
                    </Typography>
                    <Typography style={{ fontSize: "1.2vw" }}>oz</Typography>
                  </Box>
                  <Box
                    className={classes.spotRateGoldValueBox}
                    sx={{
                      background:
                        Number(gold?.cur?.ask) < Number(gold?.pre?.ask)
                          ? "#D63832"
                          : Number(gold?.cur?.ask) > Number(gold?.pre?.ask)
                          ? "#1C6DB9"
                          : "#002223",
                      px: 1.5,
                    }}
                  >
                    <Typography sx={{ fontSize: "2.2vw", fontWeight: "bold" }}>
                      {!gold?.cur?.bid
                        ? "0.00"
                        : (
                            Number(spread.goldAskSpread) +
                            Number(gold?.cur?.ask)
                          ).toFixed(2)}
                    </Typography>
                  </Box>
                  <Box className={classes.highLowBox}>
                    <Box className={classes.high}>
                      <Typography
                        style={{ fontSize: ".8vw", color: "#FFFFFF" }}
                      >
                        High
                      </Typography>
                    </Box>
                    <Typography style={{ fontSize: "1vw" }}>
                      {!gold?.cur?.bid
                        ? "0.00"
                        : (
                            Number(spread.goldHighSpread) +
                            Number(gold?.cur?.highPrice)
                          ).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ height: "40%", width: "100%", display: "flex" }}>
                <Box
                  sx={{ width: "30%", height: "100%", p: 3, paddingLeft: 6 }}
                >
                  <Typography sx={{ fontSize: "1.4vw", fontWeight: "bold" }}>
                    SILVER
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "35%",
                    height: "100%",
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      background:
                        Number(silver?.cur?.bid) < Number(silver?.pre?.bid)
                          ? "#D63832"
                          : Number(silver?.cur?.bid) > Number(silver?.pre?.bid)
                          ? "#1C6DB9"
                          : "#002223",

                      px: 1.5,
                    }}
                    className={classes.spotRateSilverValueBox}
                  >
                    <Typography sx={{ fontSize: "2vw" }}>
                      &nbsp;&nbsp;
                      {!silver?.cur?.bid
                        ? "0.00"
                        : (
                            Number(spread.silverBidSpread) +
                            Number(silver?.cur?.bid)
                          ).toFixed(3)}
                      &nbsp;&nbsp;
                    </Typography>
                  </Box>
                  <Box className={classes.highLowBox}>
                    <Box className={classes.low}>
                      <Typography
                        style={{ fontSize: ".8vw", color: "#FFFFFF" }}
                      >
                        Low
                      </Typography>
                    </Box>
                    <Typography style={{ fontSize: "1vw" }}>
                      {!silver?.cur?.bid
                        ? "0.00"
                        : (
                            Number(spread.silverLowSpread) +
                            Number(silver?.cur?.lowPrice)
                          ).toFixed(3)}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "35%",
                    height: "100%",
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  <Box
                    className={classes.spotRateSilverValueBox}
                    sx={{
                      background:
                        Number(silver?.cur?.ask) < Number(silver?.pre?.ask)
                          ? "#D63832"
                          : Number(silver?.cur?.ask) > Number(silver?.pre?.ask)
                          ? "#1C6DB9"
                          : "#002223",

                      px: 1.5,
                    }}
                  >
                    <Typography sx={{ fontSize: "2vw", ml: 1 }}>
                      &nbsp;&nbsp;
                      {!silver?.cur?.bid
                        ? "0.00"
                        : (
                            Number(spread.silverAskSpread) +
                            Number(silver?.cur?.ask)
                          ).toFixed(3)}
                      &nbsp;&nbsp;
                    </Typography>
                  </Box>
                  <Box className={classes.highLowBox}>
                    <Box className={classes.high}>
                      <Typography
                        style={{ fontSize: ".8vw", color: "#FFFFFF" }}
                      >
                        High
                      </Typography>
                    </Box>
                    <Typography style={{ fontSize: "1vw" }}>
                      {!silver?.cur?.bid
                        ? "0.00"
                        : (
                            Number(spread.silverHighSpread) +
                            Number(silver?.cur?.highPrice)
                          ).toFixed(3)}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={classes.time}>
              <Box
                sx={{
                  width: "90%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.2vw",
                    textAlign: "center",
                    fontWeight: 500,
                    background: "#000000",
                    color: "white",
                    width: "30%",
                  }}
                >
                  GOLD
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: 5,
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: "1.2vw" }}>Am Fix</Typography>
                    <Typography
                      sx={{
                        fontSize: "1.9vw",
                        px: 2,
                        border: "1px solid #000000",
                        borderRadius: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      {londonFixValue?.goldAM || "0.00"}
                      {/* {londonFixValue?.gold?.amValue || "0.00"} */}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: "1vw" }}>PM Fix</Typography>
                    <Typography
                      sx={{
                        fontSize: "1.9vw",
                        px: 2,
                        border: "1px solid #000000",
                        borderRadius: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      {londonFixValue?.goldPM || "0.00"}
                      {/* {londonFixValue?.gold?.pmValue || "0.00"} */}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "90%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.2vw",
                    textAlign: "center",
                    fontWeight: 500,
                    background: "#000000",
                    color: "white",
                    width: "30%",
                  }}
                >
                  SILVER
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: 5,
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: "1vw" }}>Am Fix</Typography>
                    <Typography
                      sx={{
                        fontSize: "1.9vw",
                        px: 2,
                        border: "1px solid #000000",
                        borderRadius: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      {londonFixValue?.silver || "0.00"}
                      {/* {londonFixValue?.silver?.amValue || "0.00"} */}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: "1vw" }}>PM Fix</Typography>
                    <Typography
                      sx={{
                        fontSize: "1.9vw",
                        px: 2,
                        border: "1px solid #000000",
                        borderRadius: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      {londonFixValue?.silver || "0.00"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.commodity}>
            <Box className={classes.banner}>
              <Box className={classes.carousel}>
                <Carousel
                  animation="fade" // Set the animation type to slide
                  navButtonsAlwaysVisible={false} // Show navigation buttons always
                  interval={10000}
                  indicatorContainerProps={{
                    style: {
                      display: "none",
                    },
                  }}
                  sx={{
                    height: "100%",
                    width: "100%",
                  }}
                  transitionTime={3000} // Adjust the transition time to control the speed of the fade effect
                  autoPlay
                  duration={2000}
                >
                  {banners?.map((banner, i) => (
                    <img
                      className={classes.bannerImg}
                      src={banner.imageUrl}
                      alt="banner"
                    />
                  ))}
                </Carousel>
              </Box>
            </Box>
            <Box className={classes.table}>
              <Box className={classes.tableHeader}>
                <Typography
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "left",
                    paddingLeft: 4,
                    fontSize: "2vw",
                    fontWeight: "bold",
                  }}
                >
                  COMMODITY
                </Typography>
                <Typography
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    paddingLeft: 2,
                    fontSize: "2vw",
                    fontWeight: "bold",
                  }}
                >
                  WEIGHT
                </Typography>
                <Typography
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "left",
                    paddingLeft: 4,
                    alignItems: "center",
                    fontSize: "2vw",
                    fontWeight: "bold",
                  }}
                >
                  BUY
                  <Typography sx={{ fontSize: "0.9vw", mt: 2.2 }}>
                    &nbsp;AED
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "left",
                    paddingLeft: 4,
                    alignItems: "center",
                    fontSize: "2vw",
                    fontWeight: "bold",
                  }}
                >
                  SELL
                  <Typography sx={{ fontSize: "0.9vw", mt: 2.2 }}>
                    &nbsp;AED
                  </Typography>
                </Typography>
              </Box>

              <Box className={classes.tableContent}>
                {commodities?.map((commodity, idx) => {
                  const words = commodity.commodity_title.split(" ");
                  return (
                    <Box key={idx} className={classes.tableRow1}>
                      <Typography
                        style={{ fontSize: "1.5vw", fontWeight: "bold" }}
                        className={classes.tableRowColumn}
                      >
                        {words.map((word, index) => {
                          if (index === 0) {
                            return (
                              <span
                                key={index}
                                style={{
                                  fontSize: "2vw",
                                  fontWeight: "bold",
                                }}
                              >
                                {word}
                              </span>
                            );
                          } else {
                            return (
                              <span
                                key={index}
                                style={{
                                  fontSize: "1vw",
                                  marginTop: "0.2rem",
                                  fontWeight: "bold",
                                }}
                              >
                                &nbsp;{word}
                              </span>
                            );
                          }
                        })}
                        &nbsp;
                        {commodity.unitLabel === "TTB"
                          ? ""
                          : commodity.displayPurity}
                      </Typography>
                      <Typography
                        style={{
                          padding: 0,
                          fontSize: "2vw",
                          fontWeight: "bold",
                          justifyContent: "center",
                        }}
                        className={classes.tableRowColumn}
                      >
                        {commodity.unit}
                        {commodity.unitLabel}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "2vw",
                          fontWeight: "bold",
                        }}
                        className={classes.tableRowColumn}
                      >
                        {!gold?.cur?.bid
                          ? "0.00"
                          : commodityCalculation(
                              commodity.commodity_title === "Silver"
                                ? silver?.cur?.bid
                                : gold?.cur?.bid,
                              commodity.commodity_title === "Silver"
                                ? spread.silverBidSpread
                                : spread.goldBidSpread,
                              commodity.buy_premium,
                              3.674,
                              commodity.purity,
                              commodity.unit,
                              commodity.unitLabel === "TTB"
                                ? 116.64
                                : commodity.unitLabel === "KG"
                                ? 1000
                                : commodity.unitLabel === "OZ"
                                ? 31.1
                                : commodity.unitLabel === "TOLA"
                                ? 11.7
                                : 1,
                              commodity.buy_charge
                            )}
                      </Typography>
                      <Typography
                        style={{ fontSize: "2vw", fontWeight: "bold" }}
                        className={classes.tableRowColumn}
                      >
                        {!gold?.cur?.bid
                          ? "0.00"
                          : commodityCalculation(
                              commodity.commodity_title === "Silver"
                                ? silver?.cur?.ask
                                : gold?.cur.ask,
                              commodity.commodity_title === "Silver"
                                ? spread.silverAskSpread
                                : spread.goldAskSpread,
                              commodity.premium,
                              3.674,
                              commodity.purity,
                              commodity.unit,
                              commodity.unitLabel === "TTB"
                                ? 116.64
                                : commodity.unitLabel === "KG"
                                ? 1000
                                : commodity.unitLabel === "OZ"
                                ? 31.1
                                : commodity.unitLabel === "TOLA"
                                ? 11.7
                                : 1,
                              commodity.charges
                            )}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
          <Box className={classes.updates}>
            <Box className={classes.updatesContent}>
              <marquee
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  {!adminData?.isNewsEnable &&
                  Object.keys(goldNews).length > 0 ? (
                    <Box
                      style={{
                        fontSize: "1.6vw",
                        fontFamily: "poppins",
                        alignItems: "center",
                        // display: "flex",
                        fontStyle: "italic",
                      }}
                    >
                      <span style={{ fontWeight: 500 }}>Gold Price News</span>
                      &nbsp;&nbsp;:&nbsp;&nbsp;<span>{goldNews?.title}</span>
                    </Box>
                  ) : (
                    news?.map((item, index) => {
                      return (
                        <Box
                          key={item._id}
                          style={{
                            fontSize: "1.5vw",
                            fontFamily: "poppins",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <span style={{ fontWeight: 500 }}>
                            {item.newsTitle}
                          </span>
                          &nbsp;&nbsp;:&nbsp;&nbsp;
                          <span>{item.newsDetails}</span>
                          <span
                            style={{
                              paddingRight: "50px",
                              paddingLeft: "50px",
                            }}
                          >
                            {index === news.length - 1 ? "" : "|"}
                          </span>
                        </Box>
                      );
                    })
                  )}
                </Box>
              </marquee>
            </Box>
            <Box
              sx={{
                height: "70%",
                width: "100%",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  flex: ".35",
                  width: "10%",
                  background: "#FFFFFF",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img style={{ width: "60%" }} src={icon} alt="bullionStats" />
              </Box>
              &nbsp;&nbsp;
              <Box
                sx={{
                  flex: "2",
                  width: "10%",
                  background: "#FFFFFF",
                  display: "flex",
                  alignItems: "center",
                  clipPath: " polygon(0% 0, 100% 0, 97% 100%, 0 100%)",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      px: 5,
                      py: 1,
                      boxSizing: "border-box",
                    }}
                  >
                    <Typography sx={{ fontSize: "1vw", color: "#25B822" }}>
                      BUYERS
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "1vw",
                        color:
                          Number(ratio?.chgValue.replace("%", "")) >= 0
                            ? "#25B822"
                            : "#E2261F",
                      }}
                    >
                      {ratio?.chgValue}
                    </Typography>
                    <Typography sx={{ fontSize: "1vw", color: "#D63832" }}>
                      SELLERS
                    </Typography>
                  </Box>
                  <Box sx={{ width: "90%", px: 4 }}>
                    <LinearProgress
                      variant="determinate"
                      value={progress}
                      style={progressStyle}
                      sx={{
                        "& .MuiLinearProgress-bar": barStyle,
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      px: 7,
                      py: 1,
                      boxSizing: "border-box",
                    }}
                  >
                    <Typography sx={{ fontSize: "1vw", color: "#25B822" }}>
                      {ratio?.Buyers}
                    </Typography>
                    <Typography sx={{ fontSize: "1vw", color: "#E2261F" }}>
                      {Number(ratio?.Buyers?.match(/\d+/)[0])
                        ? 100 - Number(ratio?.Buyers?.match(/\d+/)[0])
                        : 0}
                      %
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  flex: "1",
                  width: "10%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <a href="https://www.bullionstats.com/">
                  <img
                    className={classes.bullionStatsImg}
                    src={bullionStats}
                    alt="bullionStats"
                  />
                </a>
              </Box>
              <Box
                sx={{
                  flex: "1",
                  width: "10%",
                  background: "#FFFFFF",
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "center",
                  flexDirection: "column",

                  clipPath: "polygon(5% 0, 100% 0, 102% 100%, 0 100%)",
                }}
              >
                <Box sx={{ display: "flex", gap: 2, pl: 10 }}>
                  <Typography
                    sx={{
                      color: "#FFFFFF",
                      fontWeight: "bold",
                      background: "#337AA6",
                      padding: "0px 5px 0px 5px",
                      fontSize: ".8vw",
                    }}
                  >
                    DXY
                  </Typography>
                  <Typography
                    sx={{
                      color: "#191919",
                      fontWeight: "bold",
                      fontSize: ".8vw",
                    }}
                  >
                    US
                  </Typography>
                  <Typography
                    sx={{
                      color: "#191919",
                      fontWeight: "bold",
                      fontSize: ".8vw",
                    }}
                  >
                    OPEN
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", gap: 2, alignItems: "end", pl: 10 }}
                >
                  <Typography
                    sx={{
                      fontSize: "1.5vw",
                      color: "#191919",
                      fontWeight: "bold",
                    }}
                  >
                    {dxyValue?.values?.length
                      ? Number(dxyValue?.values[0]?.open).toFixed(2)
                      : ""}
                  </Typography>
                </Box>
                <Typography sx={{ color: "#6D7590", fontSize: ".7vw", pl: 10 }}>
                  Last Updates:
                  {dxyValue?.values?.length > 1
                    ? moment(dxyValue?.values[1]?.datetime).format(
                        "DD MMM YYYY hh:mm a"
                      )
                    : ""}
                </Typography>
              </Box>
              <Box
                sx={{
                  flex: ".5",
                  width: "10%",
                  background: "#D9D9D9",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  color: "#000000",
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: "bold", mb: -1 }}>
                  PREVIOUS
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  CLOSE
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {dxyValue?.values?.length > 1
                    ? Number(dxyValue?.values[1]?.open).toFixed(2)
                    : ""}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal open={openBlocked} closeAfterTransition>
        <Blocked handleClose={handleCloseBlocked} />
      </Modal>
      <Modal open={openSubscriptionExpierd} closeAfterTransition>
        <SubscriptionExpired handleClose={handleCloseSubscriptionExpierd} />
      </Modal>
      <Modal
        open={openSubscriptionExpireSoon}
        onClose={handleOpenExpireSoonClose}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.1)", // Adjust the color and opacity as needed
            backdropFilter: "none", // This disables the blur effect
          },
        }}
      >
        <SubscriptionExpiringSoon
          date={adminData?.package.expire_date}
          handleClose={handleOpenExpireSoonClose}
        />
      </Modal>
      <Modal
        open={openNews}
        onClose={handleCloseopenNews}
        closeAfterTransition
        hideBackdrop
      >
        <EconomicNewsModal
          handleClose={handleCloseopenNews}
          curEconomicNews={curEconomicNews}
        />
      </Modal>
    </div>
  );
};

export default Home;
