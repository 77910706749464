import axios from "axios";

const host = process.env.REACT_APP_BACKEND_URL;
const adminId = process.env.REACT_APP_ADMIN_ID;

export const getSocketUrl = async () => {
  try {
    const { data } = await axios.get(`${host}/api/v1/user/socket `);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAdminProfile = async () => {
  try {
    const { data } = await axios.get(`${host}/api/v1/tv/profile/${adminId}`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getSpread = async () => {
  try {
    const { data } = await axios.get(`${host}/getSpread/${adminId}`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getRatio = async () => {
  try {
    const data = await axios.get(`${host}/getGoldRatioByScrap`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getCurrencyValueWithAskBid = async () => {
  try {
    const { data } = await axios.get(`${host}/getCurrencyValueByScrap`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllImages = async () => {
  try {
    const data = await axios.get(`${host}/getAllImages/${adminId}`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const allNewses = async () => {
  try {
    const { data } = await axios.get(`${host}/allNewses/${adminId}`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getGoldPriceNews = async () => {
  try {
    const { data } = await axios.get(`${host}/api/v1/user/gold-news`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const allCommodities = async () => {
  try {
    const { data } = await axios.get(`${host}/allCommodities/${adminId}`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getConversionValue = async (base) => {
  try {
    const { data } = await axios.get(`${host}/getConversionValue/${base}`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const findBanners = async () => {
  try {
    const { data } = await axios.get(`${host}/getAllImages/${adminId}`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getLiveValueTypeForDisplay = async () => {
  try {
    const { data } = await axios.get(
      `${host}/getLiveValueTypeForDisplay/${adminId}`
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getEconomicNews = async () => {
  try {
    const { data } = await axios.get(`${host}/api/v1/tv/getEconomicNews`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getDXYindexingValue = async () => {
  try {
    const { data } = await axios.get(
      "https://api.twelvedata.com/time_series?symbol=DXY&interval=1day&apikey=c243b4228c2f46f0a0c290b94bc34f1d"
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

// export const getLondonFixValue = async () => {
//   try {
//     const { data } = await axios.get(
//       `${host}/userFindLondonFixValue/${adminId}`
//     );
//     return data;
//   } catch (error) {
//     return error.response.data.message;
//   }
// };

export const getLondonFixValue = async () => {
  const requestBody = {
    query: `
      fragment LondonQuoteFragment on LondonQuote {
        ID
        goldAM
        goldPM
        timestamp
        silver
      }
      query LondonFixByYear($year: String!, $currency: String!) {
        GetLondonFixByYearV3(year: $year, currency: $currency) {
          ID
          currency
          results {
            ...LondonQuoteFragment
          }
        }
      }
    `,
    variables: {
      year: "2025",
      currency: "USD",
    },
    operationName: "LondonFixByYear",
  };

  try {
    const { data } = await axios.post(
      "https://kdb-gw.prod.kitco.com/",
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error) {
    return (
      error.response?.data?.message || error.message || "An error occurred."
    );
  }
};
